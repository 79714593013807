import React, { useEffect, useState } from 'react';
import { Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { Props } from 'prop-types';

const ProtectedRoute = () => {
    const isAuthenticated = localStorage.getItem('userid');

    return isAuthenticated ? <Outlet /> : <Navigate to="/register" />
}


export default ProtectedRoute;